<template>
  <div>
    <loading :show="loading">
      <update-article
          ref="article"
          :confirm-button-name="`${ $i18n.t('fotostudio.confirm_and_continue')}`"
          :data="data"
          :sku="sku"
          @confirm="confirm"
      />
    </loading>
  </div>
</template>
<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import UpdateArticle from "@/views/fotostudio/components/updateArticle.vue";
import DataService from "@/views/fotostudio/services/DataService";

export default {
  components: {UpdateArticle},
  data() {
    return {
      service: new DataService(),
      sku: this.$route.params.sku,
      data: {},
      loading: false,
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    i18n() {
      return i18n
    },
    getData() {
      this.loading = true
      this.service.getFields(this.sku).then(response => {
        this.data = response.data.data
        this.loading = false
      }).catch(() => {
        this.$router.replace(`/fotostudio/articles/`)
      })
    },
    confirm(data) {
      this.loading = true
      this.service.confirmArticleByStaff(this.sku, data).then(response => {
        this.$router.replace(`/fotostudio/articles`).then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$i18n.t('Success'),
              icon: 'EditIcon',
              variant: 'success',
              text: response.data.message
            },
          })
        })
      }).catch(() => {
        this.loading = false
      })
    }
  }
}
</script>
